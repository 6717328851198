<template>
  <v-main>
    <v-row v-if="loading" class="mx-16 mt-16">
      <v-col v-for="index in 6" :key="index" cols="2">
        <v-skeleton-loader
            v-bind="skeletonAttrs"
            type="list-item-avatar, list-item-three-line, divider, list-item"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="mx-md-16" v-else>
      <v-col :class="$vuetify.breakpoint.mobile ? 'text-center' : ''">
        <h3 class="text-md-h3 text-h5 text-center font-weight-bold my-3 secondary-prosk" v-html="$t('saveTime')">
        </h3>
        <h3 class="text-md-h6 text-h6 my-3 text-center">{{ $t('AlreadyDidIt') }}</h3>
      </v-col>
      <v-col cols="12" class="mx-0 py-0">
        <carousel :scrollPerPage="false" :perPage="1" :perPageCustom="[[768, 2], [1024, 3]]">
          <slide v-for="(item, n) in data" :key="n" class="w-100">
            <v-card
              :loading="loading"
              class="border-radius-medium pt-8 pb-10 pl-8 pr-8 ml-md-4 mx-auto mx-md-0 mb-2 d-flex flex-column justify-content-between card"
              :width="$vuetify.breakpoint.mobile ? '90%' : '350px'"
            >
              <v-card-title @click="goToUserProfile(item)" class="p-0 border-radius-medium" style="box-shadow: 0px 1px 60px 0px #1414141A;">
                <v-row class="d-flex flex-column w-100">
                  <v-col cols="12">
                    <v-img
                      height="160"
                      class=""
                      :src="item.banner_img ? item.banner_img : 'https://mdbootstrap.com/img/new/standard/nature/187.jpg'"
                      style="border-radius: 32px 32px 0 0;"
                    ></v-img>
                    <v-avatar height="5rem" class="mr-2 w-100 d-flex flex-column justify-space-between" style="margin-top: -35px;">
                      <img
                          v-if="item.profile_img"
                          :src="item.profile_img"
                          class="img-fluid rounded-circle border border-white avatar-70 mb-10"
                          alt="user"
                      />
                      <img
                          v-else
                          src="@/assets/images/page-img/avatar.png"
                          class="img-fluid rounded-circle border border-white avatar-50"
                          alt="user"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <p class="name">{{ item.first_name }} {{ item.last_name }}</p>
                    <h5 class="text-subtitle-1 green-prosk">{{ item.category_name[0] }}</h5>
                    <v-rating
                      :value="item.rating"
                      half-increments
                      readonly
                      small
                      color="#6B71F2"
                      dense
                      class="my-0 pb-16"
                      style="margin-top: -12px;"
                    ></v-rating>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>
          </slide>
        </carousel>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import axios from 'axios';
import { Carousel, Slide } from 'vue-carousel';

import errorDialog from '@/components/socialvue/mixins/errorDialog';
import goToUserProfile from '@/components/socialvue/mixins/goToUserProfile';
export default {
  name: 'ProskerFeatured',
  mixins: [errorDialog, goToUserProfile],
  components: {
    Carousel,
    Slide
  },
  data () {
    return {
      data: [],
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      loading: true
    };
  },
  created () {
    this.getUsers();
  },
  methods: {
    getUsers () {
      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/users?filters=rating=4|5|per_page=8&sortBy=created_at,desc`;
      axios.get(url).then((response) => {
        this.data = response.data.data;
        this.loading = false;
      }).catch((error) => {
        this.showError(error);
        this.loading = false;
      });
    }
  }
};
</script>
<style scoped>
  .card {
    border-radius: 12px;
    padding: 12px;
  }

  .round {
    border-radius: 12px;
    width: 100%;
  }

  .p-posted {
    color: #59D79D;
    font-size: .8rem !important;
  }

  .name {
    color: #3F44A6;
    font-size: 1.2rem !important;
    margin-top: -20px;
  }

</style>
